.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;

  background: rgba($color_gray_light_4, 0.5);
}

.description {
  font-size: 0.9rem;
  padding: 1rem;
}

.loader {
  color: official;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.loader div {
  transform-origin: 20px 20px;
  animation: loader 1.2s linear infinite;
}

.loader div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 1.5px;
  left: 18.5px;
  width: 3px;
  height: 9px;
  border-radius: 10%;
  background: $color_primary_main;
}

.loader div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.loader div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.loader div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.loader div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.loader div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.loader div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.loader div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.loader div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.loader div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.loader div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.loader div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.loader div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
