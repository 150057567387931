.callout {
  background-color: lighten($color_primary_light, 25);
  border: 1px solid lighten($color_primary_light, 15);
  padding: 1rem;
  border-radius: $border_radius;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  &-icon {
    margin-right: 1rem;
    color: $color_primary_dark;
  }
  &-description {
    line-height: 1.5rem;
    font-size: 0.9rem;
  }
  &-icon {
    display: flex;
  }
}

.callout.info,
.Toastify__toast--info {
  background-color: lighten($color_primary_light, 25) !important;
  border: 1px solid lighten($color_primary_light, 15);
  .Toastify__close-button {
    color: $color_primary_main;
  }
  .callout-icon {
    color: $color_primary_dark !important;
  }
}

.callout.error,
.Toastify__toast--error {
  background-color: lighten($color_error_light, 25) !important;
  border: 1px solid lighten($color_error_light, 15);
  .Toastify__close-button {
    color: $color_error_main;
  }
  .callout-icon {
    color: $color_error_dark !important;
  }
}

.callout.success,
.Toastify__toast--success {
  background-color: lighten($color_success_light, 25) !important;
  border: 1px solid lighten($color_success_light, 15);
  .Toastify__close-button {
    color: $color_success_main;
  }
  .callout-icon {
    color: $color_success_dark !important;
  }
}

.callout.warning,
.Toastify__toast--warning {
  background-color: lighten($color_warning_light, 25) !important;
  border: 1px solid lighten($color_warning_light, 15);
  .Toastify__close-button {
    color: $color_warning_main;
  }
  .callout-icon {
    color: $color_warning_dark !important;
  }
}
