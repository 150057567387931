/* margins */

.mb-1-rem {
  margin-bottom: 1rem;
}

.mt-1-rem {
  margin-top: 1rem;
}

.mt-0-rem {
  margin-top: 0;
}

.mb-0-rem {
  margin-bottom: 0;
}

/* paddings */

.pb-1-rem {
  padding-bottom: 1rem;
}

/* text aligns */

.ta-center {
  text-align: center;
}

/* font-size */

.font-size-header {
  font-size: 1.1rem;
}

.font-size-smaller {
  font-size: 0.9rem;
}
