$bg_light_color: #fff;

$text_color_main: #323232;
$text_color_white: #fff;

$color_primary_light: #48aff0;
$color_primary_main: #137cbd;
$color_primary_dark: #0e5a8a;

$color_success_light: #3dcc91;
$color_success_main: #0f9960;
$color_success_dark: #0a6640;

$color_warning_light: #ffb366;
$color_warning_main: #d9822b;
$color_warning_dark: #a66321;

$color_error_light: #ff7373;
$color_error_main: #db3737;
$color_error_dark: #a82a2a;

$color_gray_dark_1: #182026;
$color_gray_dark_2: #202b33;
$color_gray_dark_3: #293742;
$color_gray_dark_4: #30404d;
$color_gray_dark_5: #394b59;

$color_gray_1: #5c7080;
$color_gray_2: #738694;
$color_gray_3: #8a9ba8;
$color_gray_4: #a7b6c2;
$color_gray_5: #bfccd6;

$color_gray_light_1: #ced9e0;
$color_gray_light_2: #d8e1e8;
$color_gray_light_3: #e1e8ed;
$color_gray_light_4: #ebf1f5;
$color_gray_light_5: #f5f8fa;

$border_radius: 3px;

$border-outlined-button-default: 0 0 0 1px rgba($color_primary_main, 0.5),
  0 0 0 rgba($color_primary_main, 0), 0 0 0 rgba($color_primary_main, 0);

$border-default: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
  0 0 0 rgba(16, 22, 26, 0);
$input-border: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
  inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
$input-border-focus: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
  inset 0 1px 1px rgba(16, 22, 26, 0.2);
