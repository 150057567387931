@import '../node_modules/react-toastify/dist/ReactToastify.css';

@import './styles/variables.scss';
@import './styles/helpers.scss';
@import './styles/callout.scss';
@import './styles/predefinedLitersButton.scss';

@import './styles/pageInfo.scss';
@import './styles/pagePaymentMethods.scss';

@import './styles/loader.scss';
@import './styles/phones.scss';
@import './styles/logo.scss';
@import './styles/inputForm.scss';
@import './styles/toasts.scss';
@import './styles/modalWindow.scss';

html,
body,
#root {
  height: 100%;
}

p {
  margin: 0.5rem 0;
}

a,
.link {
  text-decoration: none;
  color: $color_primary_main;
  cursor: pointer;
  &:hover {
    color: $color_primary_dark;
  }
}

.pageWrapper {
  max-width: 450px;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

label {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: block;
  width: 100%;
  &.required-field {
    position: relative;
    &::after {
      content: '*';
      color: $color_error_main;
      font-size: 0.95rem;
      padding: 0 0.25rem;
      display: inline-block;
      position: absolute;
      top: -2px;
    }
  }
}

input {
  font-size: 1.2rem;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: $border_radius;
  padding: 1rem;
  background-color: $color_gray_light_5;
  outline: none;
  -webkit-appearance: none;
  color: $color_gray_dark_1;
  &:disabled {
    -webkit-text-fill-color: $color_gray_3;
    opacity: 1; /* required on iOS */
    color: $color_gray_3;
  }
}

input[type='text'] {
  box-shadow: $input-border;
  &:focus {
    transition: 0.3s;
    box-shadow: $input-border-focus;
  }
}

input[type='button'] {
  background-color: $color_primary_main;
  color: $text_color_white;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: $border_radius;
  box-shadow: $border-outlined-button-default;
  box-sizing: border-box;
  &:disabled {
    -webkit-text-fill-color: $text_color_white;
    color: $text_color_white;
    opacity: 0.5;
  }
  &:active {
    background-color: darken($color_primary_main, 3);
  }
  &:hover {
    background-color: darken($color_primary_main, 3);
  }
}

input[type='button'].small {
  padding: 0.5rem 1rem;
}

input[type='button'].outline {
  background-color: $text_color_white;
  color: $color_primary_main;
  &:disabled {
    -webkit-text-fill-color: $text_color_white;
    color: $text_color_white;
    opacity: 0.5;
  }
  &:active {
    background-color: darken($color_primary_main, 3);
    color: $text_color_white;
  }
  &:hover {
    background-color: darken($color_primary_main, 3);
    color: $text_color_white;
  }
}

html {
  font-size: 14px;
  color: $text_color_main;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: $color_gray_light_5;
}

header {
  text-align: center;
  margin-bottom: 1rem;
  h1 {
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

footer {
  padding: 0 1rem;
}

.payment-another-method {
  margin-bottom: 1rem;
  text-align: center;
  color: $color_primary_main;
  &:hover {
    color: $color_primary_light;
  }
}

.box {
  padding: 1rem;
  background-color: $bg_light_color;
  box-shadow: $border-default;
  border-radius: $border_radius;
}

.money {
  font-size: 2rem;
}

.payment-methods-link {
  display: inline-block;
  margin: 1rem 0;
  color: $color_primary_main;
  text-decoration: none;
  font-size: 1.2rem;
  &:hover {
    color: $color_primary_light;
  }
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-input {
    flex-grow: 1;
  }
  &-amount {
    padding-right: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    flex-shrink: 0;
  }
}
