.support_description {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.phones_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .phone {
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    color: $color_primary_dark;
    a {
      color: $color_primary_dark;
      text-decoration: none;
      font-size: 1.1rem;
      &:hover {
        color: $color_primary_dark;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
    }
  }

  .application_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .telegram {
      margin-right: 0.5rem;
      color: #0088cc;
    }

    .viber {
      margin-left: 0.5rem;
      color: #7360f2;
    }

    .telegram,
    .viber {
      width: 24px;
      margin-top: 1rem;
    }
  }
}
