.input-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  margin-bottom: 1rem;

  input[type='text'] {
    padding-right: 60%;
  }

  .form-group {
    position: relative;

    .form-group-amount {
      position: absolute;
      right: 0;
      color: $color_gray_3;
    }
  }
}
