.predefined-liters-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  padding-bottom: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.predefined-liters {
  text-align: center;
  position: relative;
  &-water-amount {
    font-size: 1.2rem;
  }
  &-discount-amount {
    font-size: 0.6rem;
    position: absolute;
    top: 0.5rem;
    right: -1.3rem;
    transform: rotate(45deg);
    padding: 0.1rem 1rem;
    width: 30px;
    font-weight: bold;
    background-color: $color_error_main;
  }
  &-button {
    background-color: $color_primary_main;
    color: $text_color_white;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 1rem;
    border-radius: $border_radius;
    box-sizing: border-box;
    border: none;
    position: relative;
    overflow: hidden;
  }
}
