.modal-window-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color_gray_dark_3, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

.modal-window {
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
  &-title {
    font-size: 1.1rem;
    text-transform: uppercase;
    text-align: center;
  }
}

.modal-window-content {
  font-size: 1rem;
  line-height: 1.5rem;
}

.modal-window-footer {
  text-align: right;
  margin-top: 1rem;

  input[type='button'] {
    width: auto;
  }
}

.modal-window-btn-apply {
  margin-left: 1rem;
}
