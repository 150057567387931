.Toastify__toast {
  margin-top: 1rem;
  padding: 1rem;
}

.Toastify__toast-body {
  line-height: 1.5rem;
  font-size: 0.9rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $text_color_main;
  padding: 0 1rem 0 0;
}
